<template>
  <div class="recent-camera-list">
    <div class="wrapper">
      <div v-for="log in recentCameraList" :key="log.log_id" :class="`log-item ${themeName} ${openId === log.log_id ? 'open' : ''}`">
        <div :class="`info-container`">
          <EventCircle v-if="!isViewer" :value="log.event_type_id" />
          <div :class="`selectable-text ${themeName}`" @click="onClickLog(log)">[{{ detectionTime(log) }}] {{ cameraName }}</div>
          <RepetitiveInfoTooltip v-if="log.is_checked_organization" :isRepetitive="false" :log="log" />
          <img
            v-if="log.has_repetitive"
            src="@/assets/Chevron-Down_24px_2_Icon.svg"
            width="24px"
            :class="{ 'info-container-caret': true, rotate180: openId === log.log_id }"
            @click="onClickCaret(log)"
          />
        </div>
        <div v-if="openId === log.log_id && loading" class="progress-circular">
          <v-progress-circular indeterminate color="#FF6E68"></v-progress-circular>
        </div>
        <div v-else-if="openId === log.log_id && !loading && childLogs.length" class="duplicate-container">
          <div v-for="(childLog, index) in childLogs" :key="index" :class="`duplicate-log ${themeName}`" @click="onClickChildLog(childLog)">
            [{{ detectionTime(childLog) }}] {{ childLog.camera_name }}
            <RepetitiveInfoTooltip v-if="childLog.is_checked_organization" :isRepetitive="true" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import EventItem from '@common/EventItem'
import Util from '@/util'
import Theme from '@/mixin/theme.mixin'
import EventCircle from '@common/EventCircle'
import RepetitiveInfoTooltip from '@common/RepetitiveInfoTooltip'

export default {
  mixins: [Theme],
  components: { EventItem, EventCircle, RepetitiveInfoTooltip },
  data() {
    return {
      childLogs: [],
      openId: -1,
      loading: false,
    }
  },
  computed: {
    ...mapState(['user', 'recentLogListv2', 'currentCam', 'currentLog']),
    recentCameraList() {
      const id = !!this.currentCam ? this.currentCam.id : this.currentLog.camera_id
      return this.recentLogListv2.filter((log) => log.camera_id === id)
    },
    cameraName() {
      return !!this.currentCam ? this.currentCam.name : this.currentLog.camera_name
    },
    isViewer() {
      return this.user.isViewer
    },
  },
  methods: {
    ...mapActions(['getLog', 'setCurrentLog', 'getRecentLogListv2', 'getChildLogs', 'setDropdownSearchResult', 'detectionPageAction/getLogAction']),
    detectionTime(log) {
      return Util.getOffsetDate(log.created_at, log.offset)
    },
    async getChildLogOfClicked(id) {
      const childLogsData = await this.getChildLogs({ id, inRepOrg: true })
      this.childLogs = childLogsData.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
    },
    async onClickCaret(log) {
      if (this.openId === log.log_id) {
        this.openId = -1
        this.childLogs = []
      } else {
        this.loading = true
        this.openId = log.log_id
        await this.getChildLogOfClicked(log.log_id)
        this.loading = false
      }
    },
    async onClickChildLog(log) {
      this.$router.push(`/detection?name=${this.currentCam.name}&id=${log.log_id || log.id}`)
      this['detectionPageAction/getLogAction'](log.log_id || log.id)
      const pageDom = document.getElementsByClassName('detection')[0]
      Util.moveScrollToTop(true, pageDom)
    },
    async onClickLog(log) {
      this.openId = log.log_id
      this.childLogs = []
      this.$router.push(`/detection?name=${this.currentCam.name}&id=${log.log_id}`)
      this['detectionPageAction/getLogAction'](log.log_id)
      const pageDom = document.getElementsByClassName('detection')[0]
      Util.moveScrollToTop(true, pageDom)
    },
  },
}
</script>

<style lang="scss" scoped>
.recent-camera-list {
  margin: 30px 30px 0;
  max-height: 585px;

  .wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
  }

  .log-item {
    width: 100%;
    padding: 0 10px 0 30px;
    border-radius: 8px;
    color: var(--f-text-black);
    background-color: var(--f-light-gray-1);
    &.open {
      background-color: #d8d9da;
      &.dark {
        background-color: var(--f-bg-high);
      }
      .info-container {
        min-height: 62px;
        margin-bottom: 2px;
      }
    }
    &.dark {
      color: var(--f-text-white-high);
      background-color: var(--f-bg-middle);
    }

    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
}

.info-container {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 60px;
  cursor: pointer;
}

.selectable-text {
  cursor: pointer;
  margin-left: 10px;
  font-weight: 400;
  font-size: 14px;
  color: #1b1c22;
  &.dark {
    color: #ffffffde;
  }
}

.duplicate-container {
  max-height: 200px;
  overflow-y: auto;
}

.duplicate-log {
  font-weight: 400;
  font-size: 14px;
  margin-left: 40px;
  margin-bottom: 20px;
  height: 20px;
  cursor: pointer;
  color: #1b1c22;
  &.dark {
    color: #ffffffde;
  }
}

.info-container-caret {
  cursor: pointer;
  margin-left: auto;
  &.rotate180 {
    transform: rotate(-180deg);
  }
}

.progress-circular {
  display: flex;
  justify-content: center;
  height: 80px;
  align-items: center;
}
</style>
