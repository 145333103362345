<template>
  <div>
    <div id="viewDiv" class="balt-theme"></div>
    <!-- <div id="elevationDiv" class="esri-widget">
      <label>Elevation: <input id="elevationInput" type="checkbox" checked="yes" /> </label>
    </div> -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import util from '@/util'
import { loadModules } from 'esri-loader'
import RotuerMixin from '@/mixin/router.mixin'
import moment from 'moment'
import Constants from '@/constants'
import { auth } from '@/api'

export default {
  mixins: [RotuerMixin],
  props: {
    /**
     * emit events
     * cardinal-direction
     */
    currentCam: Object,
    currentLog: Object,
  },
  data() {
    return {
      // cameraIcon: 'CameraPointer_Icon.svg',
      cameraIcon: 'CurrentLocation_Filled_40px_Icon.svg',
      // cameraIconNonDirection: 'Target_SolidBlack_Icon.svg',
      cameraIconNonDirection: 'CameraLocation_Filled_24px_Icon.svg',
      customGeoJsonDataIcon: 'PlannedBurn_Button.svg',
      selectedLogFireIcon: 'Exclamation_Mark_White.svg',
      arcgisMap: null,
      GraphicsLayer: null,
      sceneview: null,
      rotate: 0,
      // graphics
      graphicsList: [],
      // graphics UIDs
      clickableGraphicUIDList: [],
      // camera graphics geojson layer
      camerasDataGeoJsonLayer: null,
      // pfirs data feature layer
      pfirsDataFeatureLayer: null,
    }
  },
  computed: {
    ...mapState(['camList', 'camMap', 'recentLogList', 'logListv2', 'GeoJSONUrl', 'ArcGISMapInstance']),
    orgaizationIds() {
      let idArr = []
      for (let [key, value] of Object.entries(this.camMap)) {
        idArr.push(key)
      }
      return idArr.join(',')
    },
    latitude() {
      if (this.currentCam) {
        return this.currentCam.coordinate.split(' ')[0]
      } else {
        return this.log && this.camMap[this.log.camera_id]?.coordinate && this.camMap[this.log.camera_id].coordinate.split(' ')[0]
      }
    },
    longitude() {
      if (this.currentCam) {
        return this.currentCam.coordinate.split(' ')[1]
      } else {
        return this.log && this.camMap[this.log.camera_id]?.coordinate && this.camMap[this.log.camera_id].coordinate.split(' ')[1]
      }
    },
    degreeString() {
      return util.locaitonToDegree(this.degree)
    },
    degree() {
      if (this.currentCam) {
        return -1
      } else {
        return this.log && this.log.direction
      }
    },
    nonDirection() {
      return this.degree === -1
    },
    log() {
      let log = null
      if (this.currentLog) {
        log = this.recentLogList.find((log) => log.log_id === this.currentLog.log_id)
        if (!log) {
          log = this.logListv2.find((log) => log.log_id === this.currentLog.log_id)
        } else if (this.currentLog.log_id === log.log_id) {
          log = this.currentLog
        }
      }
      return log
    },
  },
  watch: {
    currentLog(val, old) {
      if (old && val && val.camera_id && old.camera_id && val.camera_id === old.camera_id && this.sceneview) {
        this.$log.debug('Monitor DEBUG watch currentLog updateMapGraphics 1')
        this.updateMapGraphics()
      } else if (val && val.camera_id && this.sceneview) {
        this.$log.debug('Monitor DEBUG watch currentLog updateMapGraphics 2')
        this.updateMapGraphics()
        this.centerMapToCurrentLog()
      }
      this.updatePfirsLayerParams()
    },
    currentCam(val, old) {
      if (old && val && val.id && old.id && val.id === old.id && this.sceneview) {
        this.$log.debug('Monitor DEBUG watch currentCam updateMapGraphics 1')
        this.updateMapGraphics()
      } else if (val && val.id && this.sceneview) {
        this.$log.debug('Monitor DEBUG watch currentCam updateMapGraphics 2')
        this.updateMapGraphics()
        this.centerMapToCurrentLog()
      }
      this.updatePfirsLayerParams()
    },
  },
  mounted() {
    // this.$log.debug('Map#mounted currentLog', this.currentLog)
    this.loadMap()
    this.$log.debug('Monitor DEBUG mounted updateMapGraphics')
    this.updateMapGraphics()
    this.centerMapToCurrentLog()
  },
  destroyed() {
    this.$store.commit('setArcGISMapInstance', null)
  },
  methods: {
    loadMap() {
      loadModules(
        ['esri/config', 'esri/Map', 'esri/views/SceneView', 'esri/widgets/BasemapToggle', 'esri/layers/GraphicsLayer', 'esri/layers/GeoJSONLayer'],
        {
          css: true,
        }
      ).then(async ([esriConfig, Map, SceneView, BasemapToggle, GraphicsLayer, GeoJSONLayer]) => {
        const token = await auth.getToken()

        const buildEnvURL = process.env.VUE_APP_API_URL
        const fillerBackslashCharacter = buildEnvURL[buildEnvURL.length - 1] === '/' ? '' : '/'
        const urlPrependString = buildEnvURL + fillerBackslashCharacter

        // set API key
        esriConfig.apiKey = Constants.ARCGIS_API_KEY

        // set auth token in header
        esriConfig.request.interceptors.push({
          urls: urlPrependString + 'camera',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })

        // create map with the given options
        const map = new Map({
          basemap: 'arcgis-topographic',
          ground: 'world-elevation',
        })

        this.$store.commit('setArcGISMapInstance', map)

        // Sunnyvale, CA coordinates
        let x = -122.036346
        let y = 37.368832
        if (this.currentLog) {
          if (!!this.currentLog.longitude && this.currentLog.longitude !== -1 && !!this.currentLog.latitude && this.currentLog.latitude !== -1) {
            x = this.currentLog.longitude
            y = this.currentLog.latitude
          } else {
            const currentLogCameraCoordinates = this.camMap[this.currentLog.camera_id].coordinate
            x = Number(currentLogCameraCoordinates.split(' ')[1])
            y = Number(currentLogCameraCoordinates.split(' ')[0])
          }
        } else if (this.currentCam) {
          const currentCameraCoordinates = this.currentCam.coordinate
          x = Number(currentCameraCoordinates.split(' ')[1])
          y = Number(currentCameraCoordinates.split(' ')[0])
        }

        // assign map to this view
        const view = new SceneView({
          container: this.$el,
          map,
          camera: {
            position: {
              // center map on first load on Sunnyvale: [37.368832, -122.036346]
              x, // Longitude
              y, // Latitude
              z: 40000,
            },
            // tilt: 75
          },
          zoom: 12,
          constraints: {
            altitude: {
              max: 2000000,
            },
          },
          navigation: {
            mouseWheelZoomEnabled: false,
          },
        })

        this.sceneview = view

        // add basemap toggle
        if (!this.isMobile && this.$route.path !== '/monitor') {
          const basemapToggle = new BasemapToggle({
            view,
            nextBaseMap: 'arcgis-navigation',
          })
          view.ui.add(basemapToggle, 'bottom-right')
        } else {
          view.ui.components = ['attribution']
        }

        // PFIRS fire locations data
        // custom geoJSON data renderer
        const pfirsGeoJsonDataRenderer = {
          type: 'simple',
          symbol: {
            type: 'picture-marker',
            url: this.customGeoJsonDataIcon,
            width: '32px',
            height: '32px',
          },
        }

        // custom geoJSON data labels
        const pfirsGeoJsonDataLabels = {
          symbol: {
            type: 'text',
            color: '#FFFFFF',
            haloColor: '#5E8D74',
            haloSize: '2px',
            font: {
              size: '12px',
              family: 'Noto Sans',
              style: 'italic',
              weight: 'normal',
            },
          },
        }

        const pfirsFirePopupTemplate = {
          title: '{BurnName} (PFIRS)',
          outFields: ['*'],
          content: [
            {
              type: 'custom',
              creator: (item) => {
                const longitude =
                  typeof item.graphic.geometry.longitude === 'number'
                    ? item.graphic.geometry.longitude.toFixed(6)
                    : item.graphic.geometry.longitude.toString()
                const latitude =
                  typeof item.graphic.geometry.latitude === 'number'
                    ? item.graphic.geometry.latitude.toFixed(6)
                    : item.graphic.geometry.latitude.toString()
                return `
                <table class="esri-widget__table">
                  <tbody>
                    <tr>
                      <th class="esri-feature-fields__field-header">Lat</th>
                      <td class="esri-feature-fields__field-data">${latitude}</td>
                    </tr>
                    <tr>
                      <th class="esri-feature-fields__field-header">Long</th>
                      <td class="esri-feature-fields__field-data">${longitude}</td>
                    </tr>
                  </tbody>
                </table>`
              },
            },
            {
              type: 'fields',
              fieldInfos: [
                {
                  fieldName: 'BurnDate',
                  label: 'Burn Date',
                },
                {
                  fieldName: 'BurnType',
                  label: 'Burn Type',
                },
                {
                  fieldName: 'Acres',
                  label: 'Acres',
                },
                {
                  fieldName: 'ManagingAgency',
                  label: 'Managing Agency',
                },
                {
                  fieldName: 'LastUpdated',
                  label: 'Last Updated',
                },
              ],
            },
          ],
          overwriteActions: true,
          returnGeometry: true,
        }

        const start_date = this.currentLog?.created_at
          ? moment(this.currentLog.created_at, 'YYYY-MM-DDTHH:mm:ssZ').subtract(72, 'hours').format()
          : moment().subtract(72, 'hours').format()
        const end_date = this.currentLog?.created_at ? moment(this.currentLog.created_at, 'YYYY-MM-DDTHH:mm:ssZ').format() : moment().format()

        // add geoJSON layer (geoJSON data from ArcGIS Developer Dashboard)
        const pfirsGeoJsonDataLayer = new GeoJSONLayer({
          id: 'alchera-detection-map-pfirs-geojsonlayer',
          url: urlPrependString + 'external/prescribed-burn',
          renderer: pfirsGeoJsonDataRenderer,
          labelingInfo: [pfirsGeoJsonDataLabels],
          popupTemplate: pfirsFirePopupTemplate,
          customParameters: {
            start_date,
            end_date,
          },
        })
        this.pfirsDataFeatureLayer = pfirsGeoJsonDataLayer
        map.add(pfirsGeoJsonDataLayer)

        // camera locations feature layer
        // custom geoJSON data renderer
        const camerasGeoJsonDataRenderer = {
          type: 'simple',
          symbol: {
            type: 'picture-marker',
            url: this.cameraIconNonDirection,
            width: '24px',
            height: '24px',
          },
        }

        // custom geoJSON data labels
        const camerasGeoJsonDataLabels = {
          symbol: {
            type: 'text',
            color: '#FFFFFF',
            haloColor: '#5E8D74',
            haloSize: '2px',
            font: {
              size: '12px',
              family: 'Noto Sans',
              style: 'italic',
              weight: 'normal',
            },
          },
        }

        // define "go to" action
        const goToAction = {
          title: 'Go To',
          id: 'go-to-action',
          className: 'esri-icon-link-external',
        }
        const camerasPopupTemplate = {
          title: '{name}',
          outFields: ['altitude'],
          content: [
            {
              type: 'custom',
              creator: (item) => {
                const longitude =
                  typeof item.graphic.geometry.longitude === 'number'
                    ? item.graphic.geometry.longitude.toFixed(6)
                    : item.graphic.geometry.longitude.toString()
                const latitude =
                  typeof item.graphic.geometry.latitude === 'number'
                    ? item.graphic.geometry.latitude.toFixed(6)
                    : item.graphic.geometry.latitude.toString()
                return `
                <table class="esri-widget__table">
                  <tbody>
                    <tr>
                      <th class="esri-feature-fields__field-header">Lat</th>
                      <td class="esri-feature-fields__field-data">${latitude}</td>
                    </tr>
                    <tr>
                      <th class="esri-feature-fields__field-header">Long</th>
                      <td class="esri-feature-fields__field-data">${longitude}</td>
                    </tr>
                  </tbody>
                </table>`
              },
            },
            {
              type: 'fields',
              fieldInfos: [
                {
                  fieldName: 'altitude',
                  label: 'Altitude',
                },
              ],
            },
          ],
          actions: [goToAction],
          overwriteActions: true,
          returnGeometry: true,
        }

        const camerasDataGeoJsonLayer = new GeoJSONLayer({
          url: urlPrependString + 'camera?mode=geojson',
          renderer: camerasGeoJsonDataRenderer,
          outFields: ['*'],
          labelingInfo: [camerasGeoJsonDataLabels],
          popupTemplate: camerasPopupTemplate,
        })
        this.camerasDataGeoJsonLayer = camerasDataGeoJsonLayer
        map.add(camerasDataGeoJsonLayer)

        // define graphics layer
        this.GraphicsLayer = new GraphicsLayer({
          elevationInfo: {
            mode: 'on-the-ground',
          },
        })

        const self = this

        this.GraphicsLayer.on('layerview-create', function (evt) {
          self.updateMapGraphics()
          self.centerMapToCurrentLog()
        })

        // apply graphics layer to map
        map.add(this.GraphicsLayer)

        this.arcgisMap = view

        view.when(() => {
          view.on('key-down', function (evt) {
            if (evt.key === 'Control') {
              self.sceneview.navigation.mouseWheelZoomEnabled = true
            }
          })
          view.on('layerview-create', async function (evt) {
            if (evt.layerView.type == 'feature-3d') {
              const cameraDataView = await self.sceneview.whenLayerView(self.camerasDataGeoJsonLayer)
              const orgaizationIdsList = self.orgaizationIds
              cameraDataView.filter = { where: `ID in (${orgaizationIdsList})` }
            }
          })

          view.on('key-up', function (evt) {
            if (evt.key === 'Control') {
              self.sceneview.navigation.mouseWheelZoomEnabled = false
            }
          })

          // watch the position of the cursor
          view.on('pointer-move', function (evt) {
            const screenPoint = {
              x: evt.x,
              y: evt.y,
            }
            view.hitTest(screenPoint).then((response) => {
              changeMouseCursor(response)
            })
          })

          // change cursor to "pointer" when hovering over an icon
          function changeMouseCursor(res) {
            const hitPointGraphicsList = res.results.filter((item) => item.graphic.geometry.type === 'point')
            if (hitPointGraphicsList.length > 0) {
              document.body.style.cursor = 'pointer'
            } else {
              document.body.style.cursor = 'default'
            }
          }

          view.popup.viewModel.on('trigger-action', (event) => {
            const attributes = view.popup.viewModel.selectedFeature.attributes
            if (event.action.id === 'go-to-action' && attributes.public_url && attributes.public_url !== '') {
              window.open(attributes.public_url)
            }
          })
        })
      })
    },
    updateMapGraphics() {
      loadModules(['esri/Graphic', 'esri/geometry/Circle'], {
        css: true,
      }).then(([Graphic, Circle]) => {
        // go through recent log list and check for fire location(s)
        // 1: if (fire coordinates found) draw Circle and Polyline connecting fire to camera
        // 2: if (fire coordinates not found but fire exists) draw camera direction Polyline for that log
        this.$log.debug('Monitor DEBUG update Map Graphics')
        this.graphicsList = []
        // this.clickableGraphicUIDList = []
        const NOW_MOMENT = moment()

        if (!!this.currentLog) {
          const LATITUDE = this.currentLog.latitude
          const LONGITUDE = this.currentLog.longitude
          const PAN_DIRECTION = this.currentLog.pan_direction

          // define "detection within" type ("3 Hours", "6 Hours", "24 Hours", "Extra")
          const CREATED_AT = moment(this.currentLog.created_at).format()
          const MINUTES_SINCE_FIRE_DETECTED = NOW_MOMENT.diff(CREATED_AT, 'minutes')

          let MAIN_COLOR = '#F9423A'
          let BORDER_COLOR = [249, 66, 58, 0.5]
          if (MINUTES_SINCE_FIRE_DETECTED > 24 * 60) {
            MAIN_COLOR = '#6D6D6D'
            BORDER_COLOR = [109, 109, 109, 0.5]
          } else if (MINUTES_SINCE_FIRE_DETECTED > 6 * 60) {
            MAIN_COLOR = '#384CFF'
            BORDER_COLOR = [56, 76, 255, 0.5]
          } else if (MINUTES_SINCE_FIRE_DETECTED > 3 * 60) {
            MAIN_COLOR = '#FFB800'
            BORDER_COLOR = [255, 184, 0, 0.5]
          }

          const matchedCameraName = this.currentLog.camera_name
          const matchedCameraCoordinates = this.camMap[this.currentLog.camera_id].coordinate
          const CAMERA_LATITUDE = Number(matchedCameraCoordinates.split(' ')[0])
          const CAMERA_LONGITUDE = Number(matchedCameraCoordinates.split(' ')[1])

          // fire coordinates found
          if (!!LATITUDE && !!LONGITUDE && LATITUDE !== -1 && LONGITUDE !== -1 && PAN_DIRECTION !== -1) {
            // DRAW: fire location point graphic with popup
            const currentFirePoint = {
              type: 'point',
              x: LONGITUDE,
              y: LATITUDE,
            }
            const currentFirePointSymbol = {
              type: 'simple-marker',
              color: MAIN_COLOR,
              outline: {
                color: MAIN_COLOR,
                width: 10,
              },
            }
            const direction = util.locaitonToDegree(this.currentLog.pan_direction)
            const currentFirePointPopup = {
              content: [
                {
                  type: 'custom',
                  creator: (item) => {
                    const longitude =
                      typeof item.graphic.geometry.longitude === 'number'
                        ? item.graphic.geometry.longitude.toFixed(6)
                        : item.graphic.geometry.longitude.toString()
                    const latitude =
                      typeof item.graphic.geometry.latitude === 'number'
                        ? item.graphic.geometry.latitude.toFixed(6)
                        : item.graphic.geometry.latitude.toString()
                    return `
                    <table class="esri-widget__table">
                      <tbody>
                        <tr>
                          <th class="esri-feature-fields__field-header">Detected Camera</th>
                          <td class="esri-feature-fields__field-data">${matchedCameraName}</td>
                        </tr>
                        <tr>
                          <th class="esri-feature-fields__field-header">Lat</th>
                          <td class="esri-feature-fields__field-data">${latitude}</td>
                        </tr>
                        <tr>
                          <th class="esri-feature-fields__field-header">Long</th>
                          <td class="esri-feature-fields__field-data">${longitude}</td>
                        </tr>
                        <tr>
                          <th class="esri-feature-fields__field-header">Direction</th>
                          <td class="esri-feature-fields__field-data">${direction}</td>
                        </tr>
                      </tbody>
                    </table>`
                  },
                },
              ],
              overwriteActions: true,
              returnGeometry: true,
            }
            const currentFirePointGraphic = new Graphic({
              geometry: currentFirePoint,
              symbol: currentFirePointSymbol,
              popupTemplate: currentFirePointPopup,
            })
            this.graphicsList.push(currentFirePointGraphic)
            // this.clickableGraphicUIDList.push(currentFirePointGraphic.uid)

            // DRAW: polyline from sea level to altitude of camera
            // const currentCameraPolyline = {
            //   type: 'polyline',
            //   paths: [
            //     [this.longitude, this.latitude, this.currentLog.altitude],
            //     [this.longitude, this.latitude, 0],
            //   ],
            // }
            // const currentCameraLineSymbol = {
            //   type: 'simple-line',
            //   color: [226, 119, 40],
            //   width: 2,
            // }
            // const currentCameraPolylineGraphic = new Graphic({
            //   geometry: currentCameraPolyline,
            //   symbol: currentCameraLineSymbol,
            // })
            // this.graphicsList.push(currentCameraPolylineGraphic)

            // DRAW: Fire location area graphic
            const circleGeometry = new Circle({
              center: [currentFirePoint.x, currentFirePoint.y],
              geodesic: true,
              numberOfPoints: 100,
              radius: 1.2,
              radiusUnit: 'miles',
            })
            const fillSymbol = {
              type: 'simple-fill',
              color: BORDER_COLOR,
              outline: {
                width: 1,
                color: BORDER_COLOR,
              },
            }
            const currentFireAreaGraphic = new Graphic({
              geometry: circleGeometry,
              symbol: fillSymbol,
            })
            this.graphicsList.push(currentFireAreaGraphic)
            // this.clickableGraphicUIDList.push(currentFireAreaGraphic.uid)

            // DRAW: line graphic from camera to fire location
            const cameraToFirePolyline = {
              type: 'polyline',
              paths: [
                [CAMERA_LONGITUDE, CAMERA_LATITUDE],
                [currentFirePoint.x, currentFirePoint.y],
              ],
            }
            const cameraToFireLineSymbol = {
              type: 'simple-line',
              color: MAIN_COLOR,
              width: 2,
            }
            const cameraToFirePolylineGraphic = new Graphic({
              geometry: cameraToFirePolyline,
              symbol: cameraToFireLineSymbol,
            })
            this.graphicsList.push(cameraToFirePolylineGraphic)
            // this.clickableGraphicUIDList.push(cameraToFirePolylineGraphic.uid)
          } else if (!!LATITUDE && !!LONGITUDE && LATITUDE === -1 && LONGITUDE === -1 && PAN_DIRECTION !== -1) {
            this.$log.debug('Monitor DEBUG coordinates not found', LATITUDE, LONGITUDE, PAN_DIRECTION)
            // fire coordinates not found
            const endCoordinates = this.computePosition({ lat: 0, lng: 0 }, 10000, PAN_DIRECTION)
            const endLatitude = endCoordinates.lat()
            const endLongitude = endCoordinates.lng()
            const directionText = util.locaitonToDegree(PAN_DIRECTION)

            const cameraDirectionPolyline = {
              type: 'polyline',
              paths: [
                [CAMERA_LONGITUDE, CAMERA_LATITUDE],
                [CAMERA_LONGITUDE + endLongitude, CAMERA_LATITUDE + endLatitude],
              ],
            }
            this.$log.debug('Monitor DEBUG coordinates not found 2', cameraDirectionPolyline)
            const cameraDirectionLineSymbol = {
              type: 'simple-line',
              color: MAIN_COLOR,
              width: 2,
            }
            // const detectionInfoAction = {
            //   title: 'Detection Info',
            //   id: 'detection-info-action',
            //   className: 'esri-icon-description',
            // }
            const cameraDirectionLinePopup = {
              outFields: ['*'],
              content: [
                {
                  type: 'custom',
                  creator: () => {
                    return `
                    <table class="esri-widget__table">
                      <tbody>
                        <tr>
                          <th class="esri-feature-fields__field-header">Detected Camera</th>
                          <td class="esri-feature-fields__field-data">${matchedCameraName}</td>
                        </tr>
                        <tr>
                          <th class="esri-feature-fields__field-header">Fire Direction</th>
                          <td class="esri-feature-fields__field-data">${directionText}</td>
                        </tr>
                      </tbody>
                    </table>`
                  },
                },
              ],
              // actions: [detectionInfoAction],
              overwriteActions: true,
              returnGeometry: true,
              // custom key/value
              logID: this.currentLog.log_id,
            }
            const cameraDirectionPolylineGraphic = new Graphic({
              geometry: cameraDirectionPolyline,
              symbol: cameraDirectionLineSymbol,
              popupTemplate: cameraDirectionLinePopup,
            })
            this.graphicsList.push(cameraDirectionPolylineGraphic)
            // this.clickableGraphicUIDList.push(cameraDirectionPolylineGraphic.uid)
          }
        }
        this.$log.debug('Monitor DEBUG sceneview exists', !!this.sceneview)
        if (this.sceneview) {
          // this.drawExclamationMarkIcon()
          this.$log.debug('Monitor DEBUG remove graphics')
          this.sceneview.graphics.removeAll()
          this.sceneview.graphics.addMany(this.graphicsList)
          // this.GraphicsLayer.graphics = graphicsList
        }
        this.arcgisMap = this.sceneview
      })
    },
    async updatePfirsLayerParams() {
      const startDate = this.currentLog?.created_at
        ? moment(this.currentLog.created_at, 'YYYY-MM-DDTHH:mm:ssZ').subtract(72, 'hours').startOf('day').format()
        : moment().subtract(72, 'hours').startOf('day').format()
      const endDate = this.currentLog?.created_at
        ? moment(this.currentLog.created_at, 'YYYY-MM-DDTHH:mm:ssZ').endOf('day').format()
        : moment().endOf('day').format()
      const pfirsGeoJsonLayer = this.ArcGISMapInstance.findLayerById('alchera-detection-map-pfirs-geojsonlayer')
      pfirsGeoJsonLayer.customParameters.start_date = startDate
      pfirsGeoJsonLayer.customParameters.end_date = endDate
      pfirsGeoJsonLayer.refresh()
    },
    centerMapToCurrentLog() {
      if (this.sceneview) {
        let latitude = 0
        let longitude = 0
        if (!!this.currentCam) {
          const camCoordinates = this.currentCam.coordinate.split(' ')
          latitude = Number(camCoordinates[0])
          longitude = Number(camCoordinates[1])
        } else if (
          !!this.currentLog &&
          !!this.currentLog.latitude &&
          !!this.currentLog.longitude &&
          this.currentLog.latitude !== -1 &&
          this.currentLog.longitude !== -1
        ) {
          // lat/lng exist, not -1
          latitude = this.currentLog.latitude
          longitude = this.currentLog.longitude
        } else {
          const camCoordinates = this.camMap[this.currentLog.camera_id].coordinate.split(' ')
          latitude = Number(camCoordinates[0])
          longitude = Number(camCoordinates[1])
        }
        // calculate coordinates of midpoint of line graphic
        if (
          !!this.currentLog &&
          !!this.currentLog.longitude &&
          this.currentLog.longitude === -1 &&
          !!this.currentLog.latitude &&
          this.currentLog.latitude === -1 &&
          this.currentLog.pan_direction !== -1
        ) {
          const midpointCoordinates = this.computePosition({ lat: 0, lng: 0 }, 5000, this.currentLog.pan_direction)
          const camCoordinates = this.camMap[this.currentLog.camera_id].coordinate.split(' ')
          longitude = Number(camCoordinates[1]) + midpointCoordinates.lng()
          latitude = Number(camCoordinates[0]) + midpointCoordinates.lat()
        }

        this.sceneview
          .goTo(
            {
              center: [Number(longitude), Number(latitude)],
              zoom: 12,
            },
            {
              speedFactor: 1000,
              easing: 'linear',
            }
          )
          .catch((e) => {
            this.$log.debug('catch ArcGIS goTo() error')
          })
      }
    },
    centerMap() {
      if (this.sceneview) {
        let latitude = 0
        let longitude = 0

        if (!!this.currentCam) {
          const camCoordinates = this.currentCam.coordinate.split(' ')
          latitude = Number(camCoordinates[0])
          longitude = Number(camCoordinates[1])
        } else if (
          !!this.currentLog &&
          !!this.currentLog.latitude &&
          !!this.currentLog.longitude &&
          this.currentLog.latitude !== -1 &&
          this.currentLog.longitude !== -1
        ) {
          // lat/lng exist, not -1
          latitude = this.currentLog.latitude
          longitude = this.currentLog.longitude
        } else if (
          // calculate coordinates of midpoint of line graphic
          !!this.currentLog.longitude &&
          !!this.currentLog.latitude &&
          this.currentLog.longitude === -1 &&
          this.currentLog.latitude === -1 &&
          this.currentLog.pan_direction !== -1
        ) {
          const midpointCoordinates = this.computePosition({ lat: 0, lng: 0 }, 5000, this.currentLog.pan_direction)
          const camCoordinates = this.camMap[this.currentLog.camera_id].coordinate.split(' ')
          longitude = Number(camCoordinates[1]) + midpointCoordinates.lng()
          latitude = Number(camCoordinates[0]) + midpointCoordinates.lat()
        } else {
          const camCoordinates = this.camMap[this.currentLog.camera_id].coordinate.split(' ')
          latitude = Number(camCoordinates[0])
          longitude = Number(camCoordinates[1])
        }

        this.sceneview
          .goTo(
            {
              center: [Number(longitude), Number(latitude)],
              zoom: 12,
            },
            {
              speedFactor: 1000,
              easing: 'linear',
            }
          )
          .catch((e) => {
            this.$log.debug('catch ArcGIS goTo() error')
          })
      }
    },
    computePosition(latLng, distance, heading) {
      return google.maps.geometry.spherical.computeOffset(latLng, distance, heading)
    },
  },
}
</script>

<style>
#elevationDiv {
  position: absolute;
  bottom: 30px;
  left: 12px;
  padding: 12px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
}
</style>
