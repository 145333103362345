<template>
  <v-dialog v-model="value" max-width="400px" @keydown.esc="hide" persistent no-click-animation>
    <v-card :class="`save-label-dialog ${themeName}`">
      <v-card-text class="pa-0">
        <div :class="`save-label-dialog-title ${themeName}`">Save Event Type</div>
        <div :class="`save-label-dialog-content ${themeName}`">
          <div>Please click save to confirm<br />event type selection.</div>
        </div>
        <div class="d-flex justify-end">
          <button @click="hide" :class="`save-label-dialog-button cancel ${themeName}`">Cancel</button>
          <button @click="ok" :class="`save-label-dialog-button ok ${themeName}`">Save</button>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Theme from '@/mixin/theme.mixin'

export default {
  mixins: [Theme],
  props: {
    value: Boolean,
    /**
     * emit events
     * ok
     */
  },
  methods: {
    hide() {
      this.$emit('input', false)
    },
    ok() {
      this.$emit('ok', true)
      this.hide()
    },
  },
}
</script>

<style lang="scss" scoped>
.save-label-dialog {
  background-color: var(--f-text-white);
  width: 400px;
  &.dark {
    background-color: var(--f-bg-middle);
  }
}

.save-label-dialog-title {
  text-align: center;
  padding: 50px 0 20px;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: var(--f-text-black);
  &.dark {
    color: var(--f-text-white-high);
  }
}

.save-label-dialog-content {
  text-align: center;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 61px;
  color: var(--f-text-black);
  &.dark {
    color: var(--f-text-white-high);
  }
}

.save-label-dialog-button {
  font-weight: 600;
  font-size: 16px;
  height: 60px;
  color: var(--f-text-white-high);
  background-color: var(--f-supplementary-modal-cancel);

  &.cancel {
    width: 160px;
    color: var(--f-text-black);
    background-color: var(--f-cool-gray-1);
    &.dark {
      color: #ffffffde;
      background-color: var(--f-supplementary-modal-cancel);
    }
  }

  &.ok {
    flex-grow: 1;
    background-color: var(--f-supplementary-modal-blue);
    color: var(--f-text-white);
    &.dark {
      color: var(--f-text-white-high);
    }
  }
}
</style>
