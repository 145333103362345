<template>
  <div class="alert">
    <section @scroll="onScroll" :class="`detection ${themeName}`">
      <CameraInfo v-if="currentCam"></CameraInfo>
      <LogInfo v-if="currentLog" @onClickRootLog="onClickRootLog"></LogInfo>
      <WeatherInfo v-if="currentLog" style="margin: 0 30px 20px"></WeatherInfo>
      <div class="player">
        <NewImagePlayer v-if="currentLog" :currentLog="currentLog" ref="DetectionImagePlayerContainer">
          <template v-slot="{ currentIndex, moveFrame, playerState, imageUrlList, detectedImageIndex, hasAfterImages, play, pause, currentLogId }">
            <FramePlayerBar
              :currentLogId="currentLogId"
              :currentIndex="currentIndex"
              @moveFrame="moveFrame"
              :playerState="playerState"
              :imageUrlList="imageUrlList"
              :detectedImageIndex="detectedImageIndex"
              :hasAfterImages="hasAfterImages"
              @play="play"
              @pause="pause"
            />
          </template>
        </NewImagePlayer>
        <LiveViewPlayer v-else-if="currentCam" :currentCam="currentCam" />
      </div>
      <Loading
        v-if="showMapIsLoading"
        :loading="true"
        style="margin: 0 30px; min-height: 420px; position: relative; width: calc(100% - 60px); z-index: 3 !important"
      ></Loading>
      <div v-else class="map-container" style="styleMap" @mousewheel="onMousewheel" @keydown="onKeydown" @keyup="onKeyup">
        <div :class="{ 'map-view-scroll-guide': true, visible: isInvalidScroll, hidden: !isInvalidScroll }" :style="styleMap">
          Use Ctrl + scroll to zoom the map
        </div>
        <Map ref="detectionMap" :currentCam="currentCam" :currentLog="currentLog" :style="styleMap"></Map>
        <!-- map center btn -->
        <v-btn bottom class="map-center-btn" color="white" dark fab height="38" width="38" left @click="onClickMapCenterBtn()">
          <img src="@/assets/Arrow_Out_Circle_Icon_Red.svg" />
        </v-btn>
        <!-- legend -->
        <div class="legend">
          <MapDetailLegend />
        </div>
      </div>
      <RecentLogList ref="recentLogList" v-if="!currentCam && !isSearched"></RecentLogList>
      <RecentCameraList v-else-if="currentCam"></RecentCameraList>
      <Footer></Footer>
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
// import Navigation from '@common/Navigation'
import CameraInfo from '@desktop/adminOrViewer/detection/CameraInfo'
import LogInfo from '@desktop/adminOrViewer/detection/LogInfo'
import WeatherInfo from '@desktop/adminOrViewer/detection/WeatherInfo'
import NewImagePlayer from '@common/newImage/ImagePlayer'
import FramePlayerBar from '@common/newImage/FramePlayerBar'
import LiveViewPlayer from '@common/newImage/LiveViewPlayer'

// import Map from '@common/GoogleMaps/Map'
import Map from '@common/ArcGIS/DetectionMap'
import MapDetailLegend from '@common//MapDetailLegend.vue'
import Loading from '@common/ImagePlayer/Loading'
import Footer from '@desktop/adminOrViewer/detection/Footer'
import RecentCameraList from '@desktop/adminOrViewer/detection/RecentCameraList'
import RecentLogList from '@desktop/adminOrViewer/detection/RecentLogList'
import RotuerMixin from '@/mixin/router.mixin'
import SelfInfoMixin from '@/mixin/selfinfo.mixin'
import Theme from '@/mixin/theme.mixin'
import CONSTANT from '@/constants'
import Util from '@/util'

export default {
  mixins: [RotuerMixin, SelfInfoMixin, Theme],
  components: {
    CameraInfo,
    LogInfo,
    WeatherInfo,
    Loading,
    Map,
    Footer,
    RecentLogList,
    RecentCameraList,
    MapDetailLegend,
    FramePlayerBar,
    NewImagePlayer,
    LiveViewPlayer,
  },
  data() {
    return {
      intervalSiteLog: null,
      isDestroyed: false,
      LIVE_VIEW_SHOW_HOURS_THRESHOLD: 72,
      showMapIsLoading: false,
      legendKeys: [
        {
          borderColor: '#F9423A73',
          color: '#F9423A',
          text: '3 Hours',
        },
        {
          borderColor: '#FFB80073',
          color: '#FFB800',
          text: '6 Hours',
        },
        {
          borderColor: '#384CFF73',
          color: '#384CFF',
          text: '24 Hours',
        },
        {
          borderColor: '#6D6D6D73',
          color: '#6D6D6D',
          text: 'Extra',
        },
      ],
      // zoom control restriction(s)
      isPressingControlKey: false,
      isInvalidScroll: false,
      invalidScrollTimeout: null,
    }
  },
  computed: {
    ...mapState(['camMap', 'camList', 'recentLogListv2', 'eventTypeList', 'user', 'currentLog', 'currentCam']),
    styleMap() {
      if (this.currentLog) {
        return 'margin: 0 30px; min-height: 420px;'
      } else {
        return 'margin: -54px 30px 0; min-height: 420px;'
      }
    },
    isSearched() {
      const { id, name } = this.$route.query
      if ((id && name) || (id === undefined && name === undefined)) {
        return false
      }
      return true
    },
  },
  async created() {
    await this.getRecentLogListv2({ inNoti: true, inRepOrg: true, inRespStat: false, inChild: true })
    await this.initialize()
    window.onpopstate = (event) => {
      const path = event.target.location.search
      if (!path) this['detectionPageAction/forceInitialize']()
      else {
        const { id, name } = Util.getUrlQuery(path)
        this.updateDataByUrlQuery(id, name)
      }
    }
  },
  mounted() {
    this.showMapIsLoading = true
    setTimeout(() => {
      this.showMapIsLoading = false
    }, 1000)
  },
  destroyed() {
    this.isDestroyed = true
    clearTimeout(this.intervalSiteLog)
    window.onpopstate = () => {}
  },
  methods: {
    ...mapActions([
      'setCurrentLog',
      'getCamList',
      'getLogList',
      'getRecentLogListv2',
      'detectionPageAction/detectionPageIntialize',
      'detectionPageAction/nameSearchCase',
      'detectionPageAction/idSearchCase',
      'detectionPageAction/showMostRecentLogOrLiveView',
      'detectionPageAction/forceInitialize',
    ]),
    async initialize() {
      const { id, name } = this.$route.query
      await this['detectionPageAction/detectionPageIntialize']({ logId: id, cameraName: name })
      this.requestInterval()
    },
    requestInterval() {
      if (this.isDestroyed) return
      clearTimeout(this.intervalSiteLog)
      this.intervalSiteLog = setTimeout(async () => {
        await this.updateDataByUrlQuery()
        this.requestInterval()
      }, CONSTANT.INTERVAL_SITE_LOG)
    },
    async updateDataByUrlQuery() {
      const preveiosLogId = this.recentLogListv2[0]?.log_id
      await this.getRecentLogListv2({ inNoti: true, inRepOrg: true, inRespStat: false, inChild: true })
      const { id, name } = this.$route.query
      if (id !== undefined) await this['detectionPageAction/idSearchCase'](+id)
      else if (name !== undefined) await this['detectionPageAction/nameSearchCase'](name)
      else {
        await this['detectionPageAction/showMostRecentLogOrLiveView'](preveiosLogId)
      }
    },
    onClickMapCenterBtn() {
      this.$refs.detectionMap.centerMap()
    },
    async onClickRootLog(rootLog) {
      await this.$refs.recentLogList.onClickRootLog(rootLog)
    },
    onScroll() {
      if (this.currentLog) {
        this.$refs.DetectionImagePlayerContainer.closeContextMenu()
      }
    },
    onMousewheel(evt) {
      if (!this.isPressingControlKey) {
        this.isInvalidScroll = true
        clearTimeout(this.invalidScrollTimeout)
        this.invalidScrollTimeout = setTimeout(() => {
          this.isInvalidScroll = false
        }, 1000)
      }
    },
    onKeydown(evt) {
      if (evt.key === 'Control') {
        this.isPressingControlKey = true
      }
    },
    onKeyup(evt) {
      if (evt.key === 'Control') {
        this.isPressingControlKey = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.alert {
  display: flex;
  height: 100%;
  overflow: auto;
}

section {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-width: 1000px;
  height: 0;
  min-height: calc(100% - 75px);
  overflow: auto;
  background-color: var(--f-text-white);

  &.dark {
    background-color: var(--f-bg-low);
  }
}

.player {
  flex-basis: 603px;
  flex-shrink: 0;
  padding: 0 30px;
  min-height: 60vh;
}

.text-map {
  position: absolute;
  top: 15px;
  left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 14px;
  color: var(--f-text-white-high);
  background-color: var(--f-bg-middle);
  min-width: 282px;
  height: 30px;
  border-radius: 4px;
}

.legend-map-view {
  position: fixed;
  bottom: 25px;
  left: 15px;
  height: 50px;
  width: 260px;
  border-radius: 8px;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
  &.dark {
    background-color: #25262c;
  }
}

.map-container {
  position: relative;

  .map-view-scroll-guide {
    height: 100%;
    width: calc(100% - 60px);
    background-color: rgba(0, 0, 0, 0.45);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 22px;
    font-weight: 400;
    transition-property: opacity;
    transition-duration: 0.8s;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    pointer-events: none;
    &.visible {
      opacity: 1;
    }
    &.hidden {
      opacity: 0;
    }
  }
}

.legend {
  position: absolute;
  bottom: 25px;
  left: 45px;
  display: flex;
  flex-direction: column;
}

.map-center-btn {
  position: absolute;
  bottom: 157px;
  left: 45px;
}

.map-center-btn:hover {
  background-color: #f2f1f1 !important;
}
</style>
