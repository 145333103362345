<template>
  <div :class="{ 'recent-log-list': true, dark: $vuetify.theme.dark, 'no-flex-grow': !recentLogListv2.length }">
    <div class="recent-log-list-title">Recent</div>
    <div :class="{ 'recent-log-list-container': true, 'no-flex-grow': !recentLogListv2.length }">
      <div v-for="log in recentLogListv2" :key="log.log_id">
        <!-- <div :class="{ item: true, dark: $vuetify.theme.dark, open: !isViewer && openId === log.log_id }"> -->
        <div
          :class="{
            item: true,
            dark: $vuetify.theme.dark,
            open: openedId === log.log_id,
            select: selectedLogId === log.log_id && currentLog.log_id === selectedLogId,
            viewer: isViewer,
          }"
        >
          <div :class="{ 'info-container': true, viewer: isViewer }">
            <EventCircle v-if="!isViewer" :value="log.event_type_id" />
            <div class="info-container-text" @click="onClickRootLog(log)">
              {{ displayDetectionTime(log) }}
              <RepetitiveInfoTooltip v-if="log.is_checked_organization" :isRepetitive="false" />
            </div>
            <v-spacer></v-spacer>
            <img
              v-if="log.has_repetitive"
              src="@/assets/Chevron-Down_24px_2_Icon.svg"
              width="24px"
              :class="{ 'info-container-caret': true, rotate180: openedId === log.log_id }"
              @click="onClickCaret(log)"
            />
            <img
              v-else-if="!isViewer && !log.has_repetitive && $vuetify.theme.dark"
              src="@/assets/Chevron-Down_24px_2_Icon_white.svg"
              width="24px"
              :class="{ 'info-container-caret': true, rotate180: openedId === log.log_id }"
              @click="onClickCaret(log)"
            />
            <img
              v-else-if="!isViewer && !log.has_repetitive && !$vuetify.theme.dark"
              src="@/assets/Chevron-Down_24px_2_Icon-black.svg"
              width="24px"
              :class="{ 'info-container-caret': true, rotate180: openedId === log.log_id }"
              @click="onClickCaret(log)"
            />
            <img
              v-else-if="isViewer && !log.has_repetitive && !$vuetify.theme.dark"
              src="@/assets/Chevron-Down_24px_2_Icon-disabled-light.svg"
              width="24px"
              class="info-container-caret"
            />
            <img
              v-else-if="isViewer && !log.has_repetitive && $vuetify.theme.dark"
              src="@/assets/Chevron-Down_24px_2_Icon-disabled-dark.svg"
              width="24px"
            />
          </div>
          <div v-if="!isViewer && openedId === log.log_id" class="event-container">
            <EventItem
              v-if="log.event_type_id === 1 || log.event_type_id === 2"
              @click="openSaveLabelDialog(log, 2)"
              @contextmenu="onContextMenu(log, $event)"
              :selected="log.event_type_id === 2"
              :eventType="2"
              :hover="true"
              class="event"
            ></EventItem>
            <EventItem
              v-if="log.event_type_id === 1 || log.event_type_id === 3"
              @click="openSaveLabelDialog(log, 3)"
              @contextmenu="onContextMenu(log, $event)"
              :selected="log.event_type_id === 3"
              :eventType="3"
              :hover="true"
              class="event"
            ></EventItem>
            <EventItem
              v-if="log.event_type_id === 1 || log.event_type_id === 4"
              @click="openSaveLabelDialog(log, 4)"
              @contextmenu="onContextMenu(log, $event)"
              :selected="log.event_type_id === 4"
              :eventType="4"
              :hover="true"
              class="event"
            ></EventItem>
            <EventItem
              v-if="log.event_type_id === 1 || log.event_type_id === 5"
              @click="openSaveLabelDialog(log, 5)"
              @contextmenu="onContextMenu(log, $event)"
              :selected="log.event_type_id === 5"
              :eventType="5"
              :hover="true"
              class="event"
            ></EventItem>
          </div>
        </div>
        <div v-if="openedId === log.log_id && loading" class="progress-circular">
          <v-progress-circular indeterminate color="#D8D9DA"></v-progress-circular>
        </div>
        <div v-else-if="openedId === log.log_id && !loading && childLogs.length" class="duplicate-container">
          <div
            v-for="childLog in childLogs"
            :key="childLog.id"
            :class="`duplicate-log ${themeName} ${selectedLogId === childLog.id && currentLog.log_id === selectedLogId ? 'select' : ''}`"
            @click="onClickChildLog(childLog)"
          >
            {{ displayChildLog(childLog) }}
            <RepetitiveInfoTooltip v-if="childLog.is_checked_organization" :isRepetitive="true" />
          </div>
        </div>
        <div v-if="index !== recentLogListv2.length - 1" :class="`split-line ${themeName}`"></div>
      </div>
    </div>
    <div v-if="!recentLogListv2.length" :class="`no-data ${themeName}`">No data available.</div>
    <SaveLabelDialog v-model="showSaveLabelDialog" @ok="onClickSaveLabel"></SaveLabelDialog>
    <EditLabelDialog v-model="showEditLabelDialog" :data="tmpLog" @ok="onClickSaveLabel"></EditLabelDialog>
    <ContextMenu v-click-outside="closeContextMenu" :data="contextmenu" width="120" @mousedown="openEditLabelDialog">Edit</ContextMenu>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Util from '@/util'
import SaveLabelDialog from '@desktop/dialog/SaveLabelDialogAdmin'
import EditLabelDialog from '@desktop/dialog/EditLabelDialogAdmin'
import EventItem from '@common/EventItem'
import EventCircle from '@common/EventCircle'
import ContextMenu from '@common/ImagePlayer/ContextMenu' // todo
import { alert } from '@/api'
import Theme from '@/mixin/theme.mixin'
import RepetitiveInfoTooltip from '@common/RepetitiveInfoTooltip'

export default {
  mixins: [Theme],
  components: { SaveLabelDialog, EditLabelDialog, ContextMenu, EventItem, EventCircle, RepetitiveInfoTooltip },
  data() {
    return {
      recentLogList: [],
      isRepetitive: true,
      openedId: -1,
      selectedLogId: -1,
      showSaveLabelDialog: false,
      showEditLabelDialog: false,
      tmpEventId: -1,
      tmpLog: -1,
      contextmenu: { x: 0, y: 0, display: false },
      mouseoverDisabled: false,
      loading: false,
      childLogs: [],
    }
  },
  computed: {
    ...mapState(['camMap', 'currentLog', 'user', 'recentLogListv2']),
    isViewer() {
      return this.user.isViewer
    },
  },
  methods: {
    ...mapActions(['getRecentLogListv2', 'getChildLogs', 'getLog', 'detectionPageAction/getLogAction']),
    startDisableMouseoverLogTimer() {
      this.mouseoverDisabled = true
      setTimeout(() => {
        this.mouseoverDisabled = false
      }, 1000)
    },
    displayDetectionTime(log) {
      const cam = this.camMap[log.camera_id]
      if (cam) {
        const date = Util.getOffsetDate(log.created_at, log.offset)
        return `[${date}] ${cam.name}`
      } else {
        return log.log
      }
    },
    displayChildLog(log) {
      const date = Util.getOffsetDate(log.created_at, log.offset)
      return `[${date}] ${log.camera_name}`
    },
    async getChildLogOfClickedLog(id) {
      const childLogsData = await this.getChildLogs({ id, inRepOrg: true })
      this.childLogs = childLogsData.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
    },
    async onClickChildLog(log) {
      if (this.currentLog.log_id !== log.id) {
        this.selectedLogId = log.id
        this['detectionPageAction/getLogAction'](log.id)
      }
      this.scrollToTop()
    },
    async onClickCaret(log) {
      if (this.openedId === log.log_id) {
        this.openedId = -1
        this.childLogs = []
      } else {
        this.openedId = log.log_id
        this.loading = true
        await this.selectLog(log, false)
        const { id, name } = this.$route.query
        if (id || name) this.$router.push('/')
        await this.getChildLogOfClickedLog(log.log_id)
        this.loading = false
      }
    },
    async onClickRootLog(log) {
      if (this.selectedLogId === log.log_id) {
        return
      } else if (this.openedId === log.log_id) {
        await this.selectLog(log, true)
      } else if (this.openedId !== log.log_id) {
        this.openedId = -1
        this.childLogs = []
        await this.selectLog(log, true)
      }
    },
    async selectLog(log, scroll) {
      this.selectedLogId = log.log_id
      this['detectionPageAction/getLogAction'](log.log_id)
      if (scroll) this.scrollToTop()
    },
    scrollToTop() {
      const { id, name } = this.$route.query
      if (id || name) this.$router.push('/')
      const pageDom = document.getElementsByClassName('detection')[0]
      Util.moveScrollToTop(true, pageDom)
      this.startDisableMouseoverLogTimer()
    },
    openSaveLabelDialog(log, eventTypeId) {
      this.$log.debug('RecentLogList#openSaveLabelDialog', log && log.event_type_id)
      if (log.event_type_id === 1) {
        this.showSaveLabelDialog = true
        this.tmpEventId = eventTypeId
      }
    },
    openEditLabelDialog() {
      this.showEditLabelDialog = true
      this.closeContextMenu()
    },
    async onClickSaveLabel(event_type_id) {
      try {
        this.$log.debug('RecentLogList#onClickSaveLabel', this.showEditLabelDialog, event_type_id, this.tmpEventId)
        if (this.showEditLabelDialog) {
          await alert.updateEventType({ log_id: this.selectedLogId, event_type_id })
          await this.$store.dispatch('setCurrentLog', { ...this.currentLog, event_type_id })
        } else {
          await alert.updateEventType({ log_id: this.selectedLogId, event_type_id: this.tmpEventId })
          await this.$store.dispatch('setCurrentLog', { ...this.currentLog, event_type_id: this.tmpEventId })
        }
        await this.getRecentLogListv2({ inNoti: false, inRepOrg: true, inRespStat: false, inChild: true, tabIndex: 1 })
      } catch (e) {
        this.$log.error(e)
      }
    },
    onContextMenu(log, e) {
      const secondaryButton = 2
      if (e.button === secondaryButton && log.event_type_id !== 1) {
        this.contextmenu = { x: e.x, y: e.y, display: true }
        this.tmpEventId = log.event_type_id
        this.tmpLog = log
      }
    },
    closeContextMenu() {
      this.contextmenu = { x: 0, y: 0, display: false }
    },
  },
}
</script>

<style lang="scss" scoped>
.recent-log-list {
  display: flex;
  flex-direction: column;
  max-height: 495px;
  margin: 30px 30px 0;
  padding: 25px 40px;
  border-radius: 24px;
  color: var(--f-text-black);
  background-color: var(--f-light-gray-1);
  &.dark {
    background-color: var(--f-bg-middle);
    color: var(--f-text-white-high);
  }

  &.no-flex-grow {
    min-height: 140px;
  }
}

.recent-log-list-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 20px;
}

.recent-log-list-container {
  flex-grow: 1;
  overflow: auto;

  &.no-flex-grow {
    flex-grow: 0;
  }

  .item {
    padding: 15px 20px;
    height: 50px;
    transition: min-height 0.1s linear;

    .info-container {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      height: 20px;

      &.unselected:not(.viewer) {
        color: var(--f-primary-50);
      }

      .info-container-text {
        cursor: pointer;
        margin-left: 10px;
      }

      .info-container-caret {
        cursor: pointer;
      }
    }

    img {
      transition: transform 0.1s linear;
    }

    &.open {
      border-bottom: 1px solid var(--f-light-gray-2);
      &.dark {
        border-bottom: 1px solid var(--f-bg-high);
      }
    }

    &.open:not(.viewer) {
      min-height: 100px;
    }

    &.select {
      padding: 14px 20px 10px;
      background: linear-gradient(0deg, #ececec, #ececec), #f9f9f9;
      &.dark {
        background: none;
        background-color: var(--f-bg-high);
      }
    }

    .event-container {
      display: flex;
      margin-top: 12px;

      .event {
        cursor: pointer;
        margin-right: 10px;
      }
    }
  }
  .split-line {
    margin-top: -1px;
    height: 1px;
    background-color: var(--f-light-gray-2);
    &.dark {
      background-color: var(--f-bg-high);
    }
  }

  .rotate180 {
    transform: rotate(-180deg);
  }
}

.no-data {
  font-size: 14px;
  margin-top: -9px;
  text-align: center;
  color: var(--f-text-black-middle);
  overflow: visible;
  &.dark {
    color: var(--f-text-white-middle);
  }
}

.duplicate-container {
  max-height: 252px;
  overflow-y: auto;
  &.duplicate-log:last-child {
    border-bottom: none;
  }
}

.duplicate-log {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 15px 0 14px 60px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  box-sizing: border-box;

  &.select {
    background: linear-gradient(0deg, #ececec, #ececec), #f9f9f9;
    &.dark {
      background: none;
      background-color: var(--f-bg-high);
    }
  }
  &.dark {
    border-bottom: 1px solid var(--f-bg-high);
  }
  &.light {
    border-bottom: 1px solid var(--f-light-gray-2);
  }
}

.progress-circular {
  display: flex;
  justify-content: center;
  height: 80px;
  align-items: center;
}
</style>
