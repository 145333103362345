import { render, staticRenderFns } from "./RecentLogList.vue?vue&type=template&id=da9a4bd8&scoped=true&"
import script from "./RecentLogList.vue?vue&type=script&lang=js&"
export * from "./RecentLogList.vue?vue&type=script&lang=js&"
import style0 from "./RecentLogList.vue?vue&type=style&index=0&id=da9a4bd8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "da9a4bd8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VProgressCircular,VSpacer})


/* vuetify-loader */
import installDirectives from "!../../../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import ClickOutside from 'vuetify/lib/directives/click-outside'
installDirectives(component, {ClickOutside})
