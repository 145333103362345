var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"alert"},[_c('section',{class:("detection " + _vm.themeName),on:{"scroll":_vm.onScroll}},[(_vm.currentCam)?_c('CameraInfo'):_vm._e(),(_vm.currentLog)?_c('LogInfo',{on:{"onClickRootLog":_vm.onClickRootLog}}):_vm._e(),(_vm.currentLog)?_c('WeatherInfo',{staticStyle:{"margin":"0 30px 20px"}}):_vm._e(),_c('div',{staticClass:"player"},[(_vm.currentLog)?_c('NewImagePlayer',{ref:"DetectionImagePlayerContainer",attrs:{"currentLog":_vm.currentLog},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var currentIndex = ref.currentIndex;
var moveFrame = ref.moveFrame;
var playerState = ref.playerState;
var imageUrlList = ref.imageUrlList;
var detectedImageIndex = ref.detectedImageIndex;
var hasAfterImages = ref.hasAfterImages;
var play = ref.play;
var pause = ref.pause;
var currentLogId = ref.currentLogId;
return [_c('FramePlayerBar',{attrs:{"currentLogId":currentLogId,"currentIndex":currentIndex,"playerState":playerState,"imageUrlList":imageUrlList,"detectedImageIndex":detectedImageIndex,"hasAfterImages":hasAfterImages},on:{"moveFrame":moveFrame,"play":play,"pause":pause}})]}}],null,false,705789397)}):(_vm.currentCam)?_c('LiveViewPlayer',{attrs:{"currentCam":_vm.currentCam}}):_vm._e()],1),(_vm.showMapIsLoading)?_c('Loading',{staticStyle:{"margin":"0 30px","min-height":"420px","position":"relative","width":"calc(100% - 60px)","z-index":"3 !important"},attrs:{"loading":true}}):_c('div',{staticClass:"map-container",staticStyle:{},on:{"mousewheel":_vm.onMousewheel,"keydown":_vm.onKeydown,"keyup":_vm.onKeyup}},[_c('div',{class:{ 'map-view-scroll-guide': true, visible: _vm.isInvalidScroll, hidden: !_vm.isInvalidScroll },style:(_vm.styleMap)},[_vm._v(" Use Ctrl + scroll to zoom the map ")]),_c('Map',{ref:"detectionMap",style:(_vm.styleMap),attrs:{"currentCam":_vm.currentCam,"currentLog":_vm.currentLog}}),_c('v-btn',{staticClass:"map-center-btn",attrs:{"bottom":"","color":"white","dark":"","fab":"","height":"38","width":"38","left":""},on:{"click":function($event){return _vm.onClickMapCenterBtn()}}},[_c('img',{attrs:{"src":require("@/assets/Arrow_Out_Circle_Icon_Red.svg")}})]),_c('div',{staticClass:"legend"},[_c('MapDetailLegend')],1)],1),(!_vm.currentCam && !_vm.isSearched)?_c('RecentLogList',{ref:"recentLogList"}):(_vm.currentCam)?_c('RecentCameraList'):_vm._e(),_c('Footer')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }